import PropTypes from 'prop-types';
import { CustomPropTypes } from '@picter/prisma';
import { FormattedMessage } from 'react-intl';

export const childOf = ParentClass => (props, propName, componentName) => {
  const ChildClass = props[propName]; // eslint-disable-line
  if (!(ChildClass.prototype instanceof ParentClass)) {
    return new Error(
      `Invalid property "${propName}" supplied to ${componentName}. Validation failed.`,
    );
  }
  return null;
};

export const formattedMessage = PropTypes.oneOfType([
  CustomPropTypes.elementOf(FormattedMessage),
  PropTypes.string,
]);

export const matches = regex => {
  if (!(regex instanceof RegExp)) {
    return new Error('PropType "matches" accepts only RegExp instances');
  }

  // eslint-disable-next-line
  return (props, propName, componentName) => {
    const propValue = props[propName];
    if (!regex.test(propValue)) {
      return new Error(
        `Invalid prop \`${propName}\`: \`${propValue}\`:  supplied to` +
          ` \`${componentName}\`. Validation failed.`,
      );
    }
  };
};

export const formikPropTypes = {
  errors: PropTypes.object,
  values: PropTypes.object,
};
Object.defineProperty(formikPropTypes, 'isRequired', {
  value: {
    errors: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
  },
  enumerable: false,
});

export const themeShape = PropTypes.object;

export const historyShape = PropTypes.shape({
  push: PropTypes.func,
});

export const matchShape = PropTypes.shape({
  params: PropTypes.object,
  path: PropTypes.string,
});

export const locationShape = PropTypes.shape({
  pathname: PropTypes.string,
  query: PropTypes.object,
});

export const refShape = PropTypes.shape({
  current: PropTypes.object,
});
