import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { branch, compose, renderNothing, pure } from 'recompose';
import {
  DOMAIN_PER_ENV,
  getCookieValue,
  LAST_USER_ID_COOKIE,
  setCookie,
} from '@picter/react-app-core';

import { userIdSelector } from 'src/selectors';
import { TrackerContext } from '@picter/tracker';

const cookieDomain = DOMAIN_PER_ENV[process.env.REACT_APP_ENV];
const cookieName = LAST_USER_ID_COOKIE;

const IdentifyUserTracker = ({ userId }) => {
  setCookie(cookieName, userId, {
    domain: cookieDomain,
    'max-age': 10 * 30 * 24 * 60 * 60, // 10 months
  });

  return (
    <TrackerContext.Consumer>
      {({ getInstance }) => {
        const tracker = getInstance();
        tracker.identify(userId);
        return null;
      }}
    </TrackerContext.Consumer>
  );
};

IdentifyUserTracker.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default compose(
  connect(state => ({
    userId: userIdSelector(state) || getCookieValue(cookieName),
  })),
  branch(({ userId }) => !userId, renderNothing),
  pure,
)(IdentifyUserTracker);
