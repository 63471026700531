import { defineMessages } from 'react-intl';

export default defineMessages({
  labelProceed: {
    id: 'MobileWarning.buttonProceed',
    defaultMessage: 'Proceed anyway',
  },
  messageHeadline: {
    id: 'MobileWarning.messageHeadline',
    defaultMessage: 'Oh, that’s a small screen!',
  },
  messageParagraph: {
    id: 'MobileWarning.messageParagraph',
    defaultMessage:
      'We’re not optimized for smartphones, just yet. Please head on over to a larger screen (such as laptop or desktop computer) for your best viewing experience.',
  },
});
