/* eslint-disable indent */
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { api, select } from '@picter/torii';
import { compose } from 'recompose';
import isEqual from 'lodash.isequal';
import withDataLoading from 'src/hocs/with-data-loading';

const getUserData = user => {
  const userData = user.get('attributes').toJS();

  return {
    email: userData.email,
    name: userData.publicName,
    user_id: user.get('id'),
    accepted_terms_at: new Date(userData.acceptedTermsAt),
    signup_at: new Date(userData.createdAt),
  };
};

const injectAndLoadIntercom = (w, d, id) => {
  const i = function loadArgs() {
    i.c(arguments); // eslint-disable-line
  };
  i.q = [];
  i.c = function pushArgs(args) {
    i.q.push(args);
  };
  w.Intercom = i; // eslint-disable-line
  const l = () => {
    const s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${id}`;
    const x = d.getElementsByTagName('script')[0];
    x.parentNode.append(s);
  };
  setTimeout(l, 3000);
};

const intercomDefaultSettings = {
  alignment: 'left',
  hide_default_launcher: true,
};

class IntercomChat extends Component {
  constructor(props) {
    super(props);

    const { appId, user } = props;

    if (!window.Intercom) {
      window.intercomSettings = {
        ...intercomDefaultSettings,
        // hide default launcher for logged-in users
        hide_default_launcher: !!user,
      };
      injectAndLoadIntercom(window, document, appId);
    }
  }

  componentWillMount() {
    const { appId, user } = this.props;
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: appId,
        ...intercomDefaultSettings,
        ...(user && getUserData(user)),
        // hide default launcher for logged-in users
        hide_default_launcher: !!user,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { appId, user } = nextProps;

    if (window.Intercom && !isEqual(this.props, nextProps)) {
      window.Intercom('update', {
        app_id: appId,
        ...(user && getUserData(user)),
        // hide default launcher for logged-in users
        hide_default_launcher: !!user,
      });
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    if (window.Intercom) {
      window.Intercom('shutdown');
      delete window.Intercom;
    }
  }

  render() {
    return null;
  }
}

IntercomChat.propTypes = {
  appId: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Map),
};

IntercomChat.defaultProps = {
  user: null,
};

export const mapStateToProps = state => {
  const user = state.get('user');
  const userId = user.get('id');

  return {
    user: userId
      ? select(state, 'users', {
          id: userId,
        })
      : null,
  };
};

export const mapDispatchToProps = {
  load: api.load,
};

export const loadData = ({ load, user }) =>
  user &&
  load('users', {
    id: user.get('id'),
    include: ['plan.features'],
  });

export default compose(
  connect(mapStateToProps),
  withDataLoading(loadData, {
    LoadingComponent: Fragment,
  }),
)(IntercomChat);
