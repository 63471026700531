/* eslint-disable no-param-reassign */
import { matchPath } from 'react-router-dom';
import * as pathsMetaData from './paths-meta-data';

const paths = Object.keys(pathsMetaData).reduce((curr, pathKey) => {
  const pathMetaData = pathsMetaData[pathKey];
  if (pathMetaData.title) {
    // the reason to use name is avoid conflicts with document.title
    throw new Error(
      `${pathKey} is using "title" property instead of using "name".`,
    );
  }
  curr[`${pathKey}_PATH`] = pathsMetaData[pathKey].path;
  return curr;
}, {});

export const pathMappedMetaData = Object.values(pathsMetaData).reduce(
  (curr, next) => {
    curr[next.path] = next;
    return curr;
  },
  {},
);

export const getPathMetaData = pathname => {
  let matchedObj;
  const matchedPath = Object.keys(pathMappedMetaData).find(key => {
    matchedObj = matchPath(pathname, {
      path: key,
      exact: true,
    });
    return matchedObj;
  });
  const metaData = pathMappedMetaData[matchedPath];
  const params = matchedObj ? matchedObj.params : undefined;
  // Add route params as top level properties, so that we always
  // have relevant route parameters available in tracking events (e.g. callId).
  return { ...params, ...metaData };
};

export default paths;
