import styled from 'styled-components/macro';
import { Panel } from '@picter/prisma';

const MobileWarningModal = styled(Panel).attrs({
  flexDirection: 'column',
  position: 'absolute',
  top: 0,
  left: 0,
})`
  align-items: center;
  height: 100vh;
  justify-content: center;
  max-width: none;
  width: 100vw;

  h1,
  p {
    max-width: 500px;
    text-align: center;
  }
`;

export default MobileWarningModal;
